@import "@styles/theme";
@import "@styles/animations";
@import "@styles/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .previous {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    height: calc(100% - 34px);
    z-index: 4;
  }

  .next {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20%;
    height: calc(100% - 34px);
    z-index: 4;
  }
}

.story {
  position: absolute;
  user-select: none;

  img {
    user-select: none;
  }
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  z-index: 0;
}

.story {
  &,
  & > div {
    width: 100%;
    height: 100%;
  }
}

.clickCta {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 40%;
  gap: 15px;
  padding-right: 18px;
  align-items: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 40px;
  height: 20px;

  p {
    display: inline-block;
    width: 200px;
  }

  img {
    // margin-right: 16px;
    animation-name: "pointer";
    animation-duration: 7s;
    animation-iteration-count: infinite;
    padding-right: 18px;
  }
}

.imageHolder {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.scrollCta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 2rem;
  right: 1rem;
  width: 100%;

  p {
    font-size: 1rem;
    padding-right: 1.2rem;
    text-align: end;
    text-transform: capitalize;
  }

  svg {
    @include animationMargin;
    &:nth-child(1) {
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: 150ms;
    }
    &:nth-child(3) {
      animation-delay: 300ms;
    }
  }
}

.scrollVertical {
  position: absolute;
  bottom: 6rem;
  left: -3rem;
  z-index: 10;
  transform: rotate(-90deg);
  p {
    padding-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
  }
}

.container {
  position: relative;
  justify-items: start;
  overflow: hidden;
  background-color: $background-primary;
  align-items: start;
  width: 100%;
  height: calc(100vh - #{$header-size});
  min-height: calc(#{$min-mobile-height} - 44px);
}

.loadinBar {
  animation-name: loading;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.nextTopic {
  position: absolute;
  right: toRem(18);
  bottom: 15%;
  color: $text-primary;
  font-weight: bold;
  font-size: toRem(18);
  z-index: 2;

  svg {
    color: $border-primary;
    margin-left: toRem(9);
    height: toRem(12);
    width: auto;
  }
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(
    0deg,
    rgba($background-primary, 1) 0%,
    rgba($background-primary, 0) 100%
  );
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  height: 34px;
  display: flex;
  z-index: 2;
  justify-content: space-between;

  .barItemHolder {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .barItem {
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 35%);
    &.hasGone {
      background-color: #fff;
    }
  }
}
