@import "@styles/theme", "@styles/mixins";

@mixin buttonHover {
  &:not(.secondary):not(.light) {
    //border-width: 1px !important;
    //border-style: solid !important;
    background-color: #2E2E2E47;
  }
  &:not(.disabled) {
    background-color: $input-box-secondary;
    &:after {
      border-color: $background-secondary;
    }
    &.secondary {
      color: $text-secondary;
    }
    &.light {
      &:after {
        border-color: $secondary !important;
      }
    }
  }
}

.button {
  flex-shrink: 0;
  background-color: $primary;
  &.secondary {
    background-color: $input-box-primary;

    &:after {
      border: 1px solid $text-secondary;
    }
  }
  &.light {
    color: $text-secondary;
    background-color: $background-light;

    &:after {
      border: 1px solid $secondary;
    }
  }
  color: $text-primary;
  width:40px;
  height: 40px;
  &.large {
    width: 52px;
    height: 52px;
  }
  position: relative;
  transition: background-color $transition-time-quickest $transition-easing;

  &:active {
    background-color: $bullet-color-active;
  }

  @include desktop {
    &:hover {
      @include buttonHover;
    }
  }

  &:active {
    &:not(.disabled) {
      &:after {
        left: 0px !important;
        top: 0px !important;
        width: 33px;
        height: 33px;
        border-color: $primary !important;
      }
      &.secondary:after {
        border-color: $text-secondary !important;
      }
      &.light:after {
        border-color: $secondary !important;
      }
    }
  }
  &.large:after {
    width: 49px;
    height: 49px;
    left: 4px;
    top: 4px; 
    border-color: $secondary !important;
  }

  &.previous:after {
    left: -4px;
  }

  &.previous svg {
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.3 !important;
    cursor: default;
  }
}
