@import "@styles/theme", "@styles/mixins", "@styles/animations";

.positionFix {
  top: unset;
  bottom: 7%;
  //bottom: toRem(36);

  position: absolute;
  z-index: 1;
  padding: 0 clamp(30px, 5vw, 8.5rem);
  width: 100%;
  height: max-content;

  @media (max-width: 1366px) {
    bottom: 4%;

    position: absolute;
    z-index: 1;
    padding: 0 clamp(30px, 8vw, 8.5rem);
  }

  @media (max-width: 1440px) {
    bottom: 6.6%;
  }
}

.container {
  //display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 15% 1fr;
  grid-template-rows: 1fr;
  gap: 0px 20px;
  grid-template-areas: ". .";
  justify-items: start;
  align-items: start;
  width: 100%;
  height: max-content;


  @include desktop {
    display: flex;


  }

}

.scrollVertical {
  align-self: flex-end;
  padding-left: 8.5vw;

  > p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    text-wrap: nowrap;
  }
}

// First Layer
.polygonBorder {
  clip-path: polygon(18% 0%, 100% 0%, 80% 100%, -1% 100%);
  width: 170px;
  height: 94px;

  background-color: $primary;
  position: absolute;

  &.polygonActive {
    right: -46.5px;
    -webkit-clip-path: polygon(16% -5%, 93% -6%, 80% 102%, -2% 107%);
    clip-path: polygon(18% -10%, 90% 0%, 80% 100%, 0% 100%);
    width: 213px;
    height: 100px;
    background-color: white;
    position: absolute;
    bottom: -10px;
  }
}

.polygonFormater {
  clip-path: polygon(19% 0%, 100% 0%, 85% 132%, 0.7% 101%);
  width: 185px;
  height: 94px;
  position: relative;
}

.unitaryBox {
  width: 170px;
  margin: 0 clamp(0.5rem, -0.4vw, 0.5rem) 1px 0px;
  height: 88px;
  position: relative;
  .externalBox {
    transition: background-color $transition-time-quick $transition-easing;
    padding: 0 1rem;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 177px;
  }
}

.plus {
  position: absolute;
  top: 0;
  right: -2px;
}

.cardImage {
  position: absolute;
  bottom: 0;
  height: 100%;
  overflow: visible;
  z-index: -1;
  flex-direction: unset !important;
}

.cardTitleBox {
  background-color: transparent;
  color: #fff;
  height: 100%;
  font-weight: $font-weight-regular;
  text-align: left;
  height: 100%;
  max-height: 44px;
  white-space: nowrap;
  display: contents;

  @media (max-width: 1366px) {
    min-height: 40px;
    padding-top: 2px;
    //padding-left: 4px;
  }

  &:hover {
    .cardIcon {
      svg {
        color: #fff !important;
      }
    }

    p {
      color: #b0a477;
      &:before {
        background: #b0a477;
      }
    }
  }

  .cardIcon {
    display: flex;
    align-items: center;
    border-left: 1px solid $background-grey;
    width: 25%;
    height: 100%;
    justify-content: center;
    svg {
      color: #9a0000;
    }

    &.selected {
      svg {
        color: #fff;
      }
    }

    &:hover {
      color: #fff;
    }

    svg {
      width: 20px;
      height: 22px;

      @media (max-width: 1366px) {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.scrollCta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  width: 100%;
  //margin-top: 75px;
  margin-bottom: 15px;
  position: relative;
  top: 0;

  &.scrollCtaHorizontal {
    align-items: center;
    //padding: 0px;

    p {
      text-align: center;
      margin-top: 2rem;
      color: #e63d3d;
    }
  }

  &.scrollCtaVertical {
    padding: 20px;
    align-items: flex-start;
    bottom: 0px;

    p {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      transform: rotate(-90deg);
      transform-origin: calc(0% + 20px);
      width: max-content;
      height: 6px;
      padding-left: 15px;
    }

    div {
      border: 2px solid red;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      @include animationMargin;

      &:nth-child(1) {
        animation-delay: 0ms;
      }

      &:nth-child(2) {
        animation-delay: 150ms;
      }

      &:nth-child(3) {
        animation-delay: 300ms;
      }
    }
  }

  svg {
    margin-bottom: 12px;
    position: relative;
  }
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;

  @include xxl {
    margin-top: 0px;
  }
}

.hidde {
  display: none;
}
