.accordion {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: #FFF;
  font-size: large;
  cursor: pointer;
  padding: clamp(12px, 2vh, 35px) 0 clamp(12px, 2vh, 35px) 20px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: all $transition-time linear;
  position: relative;



  p {
    font-size: 1.5rem;
    font-weight: $font-weight-regular;

    &::after{
      content: "";
      width: 95%;
      height: 1px;
      background-color: #383838;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.accordion.active + .panel {
  padding-bottom: clamp(12px, 2vh, 35px);
  max-height: 40000px;
}

.accordion > .chevron {
  transition: all $transition-time-quick $transition-easing;
  transform: rotate(0);
  margin-left: -10px;
  margin-right: toRem(20);
}

.accordion.active > .chevron {
  transform: rotate(180deg);
}

.panel {
  padding-bottom: 0;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: all $transition-time linear;
  position: relative;
}
