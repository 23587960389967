@import '@styles/theme', '@styles/mixins';

.bgClassName {
  height: 40px !important;
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85px;
  display: flex;
  z-index: -1;
  background-color: #2E2E2E;
  box-shadow: 0px #{toRem(-2)} #{toRem(30)} 0px rgba(0, 0, 0, 0.75);
  padding: 1.2rem 1.5rem 1rem;
  flex-direction: row;
  transition: all $transition-time $transition-easing;

  .interest {
    svg {
      color: #000000 !important;
      width: 13px;
      height: 13px;
    }
  }

  .button {
    > div {
      background: #fff;
      outline: 1px solid white;
      padding: 0.7rem !important;


    }

    svg {
      color: #FFF;
      width: 8px;
      height: 13px;
    }

    p {
      font-family: "Neue Plak", Arial, sans-serif;
      text-transform: uppercase;
      font-size: 0.875rem !important;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }
  }


  &.active {
    z-index: 4001;
  }

  p {
    font-size: toRem(14) !important;
  }

  a p:last-child {
    height: 41px;
  }

  a:nth-child(2) {
    margin-left: toRem(20);
  }

}