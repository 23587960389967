@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  width: calc(100% - 105px);
  max-width: 500px;
  min-width: 180px;
  height: 100%;
  //overflow: auto;
  position: fixed;
  z-index: 1000;
  top: $header-size;
  color: $text-menu-link;
  left: -100%;
  box-shadow: 0px 0px 0px 0px transparent;
  background-color: $background-primary;
  transition: box-shadow, left $transition-time-quick $transition-easing;

  overflow: scroll;

  &.open {
    left: 0;
  }

  @include desktop {
    top: 0;
    height: 60px;
    transition: none;
    left: 0 !important;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .menuItemArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include desktop {
      justify-content: flex-start;
      padding-left: 0;
      width: 50%;
      gap: 40px;

      div {
        position: relative;

        &:not(:last-child)::after {
          content: "/";
          position: absolute;
          right: -1rem !important;
          transform: translate(50%, 50%);
          bottom: 30%;
          // height: 20px;
          width: 1px;
          color: $text-secondary;
          margin-left: 1rem;
        }
      }
    }
  }

  .menuItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  a {
    &:not(.menuButton) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      color: $text-primary;
      text-decoration: none;
      transition: all $transition-time-quick $transition-easing;

      @include desktop {
        line-height: normal;
        padding: 0;
      }

      span {
        display: inline-block;
        width: max-content;
        font-family: $font;
        font-weight: $font-weight-regular;
        font-size: clamp(0.9rem, 1vw, 1.5rem);

        @include desktop {
          font-size: clamp(0.72rem, 1vw, 1rem);
        }
      }
      &.active {
        color: $primary;

        @include desktop {
          &:before {
            width: 100%;
            height: 2px;
            display: flex;
            content: "";
            transition: all 200ms ease-in-out;
            background: $primary;
            position: absolute;
            left: 0;
            bottom: -20px;
          }
        }
      }
    }
  }
}

.scroller {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  height: 100%;

  @include desktop {
    flex-direction: row;
    position: unset;
    justify-content: flex-start;
    background-color: $full-black;

    a {
      width: min-content;
      flex: content;
      cursor: pointer;
    }
  }
}

.productLogo {
  padding: 15px 15px 15px 20px;
  margin-bottom: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include desktop {
    padding: 0;
    margin: 0;
    border: none;
    justify-content: center;
    margin-right: 1rem;
    flex-direction: row;
    padding: 0;
    margin-bottom: 0;
    background-color: #292929;
    width: 136px;
    object-fit: contain;
    height: 100%;
  }
}

.buttonsHolder {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-left: -2%;
  padding-bottom: 200px;

  @include desktop {
    flex-direction: row;
    margin: 0;
    padding-right: 0;
    padding-bottom: 0;
    justify-content: center;
    background-color: rgba($color: #fff, $alpha: 0.3);
  }

  .menuButton {
    margin-bottom: 1rem;


    @include desktop {
      // margin-right: 1rem;
      margin: 0;
    }

    > div {
      // background: $background-light;
      // outline: 1px solid white;
      padding: 0.7rem;

      &:hover {
        background-color: #b0a477 !important;
        p {
          color: #ffffff;
        }
      }
    }

    p {
      font-family: $font;
      font-size: 0.875rem !important;
      color: $text-primary;
      font-weight: bold;
      // text-align: center !important;
    }

    svg {
      margin-right: 1rem;
      path {
        color: $text-primary;
      }
    }
  }
}

.buttonsHolderDesktop {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  gap: 0.5rem;
  padding-right: 1rem;
}



.gestureDetector {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
}

.teste1 {
  width: 90%;
  height: 0.1px;
  display: flex;
  content: "";
  transition: all 200ms ease-in-out;
  background: #707070;
  // border: 0.4px solid #707070;
  position: absolute;
  left: 0;
  margin-left: 0.5rem;
  bottom: 0;
  // margin-top: 30px;

  &.visible {
    display: inline;
    background-color: $primary;
    height: 3px;
  }
}

.active {
  .teste1 {
    background-color: yellow;
  }
}

.apart {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}


.menuButtonDekstop {

  > div {
    width: 180px;

    @media (max-width: 1175px) {
      width: 125px;
    } 

    display: flex;
flex-shrink: 0;


    height: 40px;

    div{
      background-color: yellow;
    }
    
    p {
      font-weight: $font-weight-semibold;
      font-style: italic;
      font-size: 0.857rem;
      text-transform: none;
    }
  }
}