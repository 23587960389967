// BACKGROUNDS PLATES COLORS
$background-primary: #35101D;
$background-secondary: #EDEDE3;
$background-dark: #0B0C0D;
$background-grey: #2E2E2E;
$background-light: #fff;
$bullet-color: #CCCCCC;
$bullet-color-active: #B0A477;
$background-header: #1F1F1F;

// BRAND COLORS
$primary: #FF1430;
$secondary: #1F1F1F;
$terciary: #9D9B8F;
$navColor: #363636;

// FONTS COLORS
$text-primary: #EDEDE3;
$text-secondary: #393939;
$text-on-primary: #3A369E;
$text-on-secondary: #1C1C1C;
$text-menu-link: #FFFFFF;
$text-brand-primary: #FC1430;
$text-banner-description: #CCCCCC;
$hr-color: #9FCFD9;
$text-light: #FFF;

// DEFAULTS
$full-white: #FFF;
$full-black: #000;

// INPUTS COLORS
$input-box-primary: #FC1430;
$input-box-secondary: #636363;
$border-color: #17131E;
$font: 'Futura', Roboto, Helvetica Neue, sans-serif;
$font-secondary: 'Futura', Arial, sans-serif;
$fontawesome: 'font awesome';
$fontawesome-brands: 'font awesome brands';
$scrollbar-track: rgba($primary, 0.23);
$scrollbar-body: $primary;
$border-primary: $secondary;
$border-size: clamp(8px, 2vh, 18px);


$font-abarth: 'FuturaSTD';
$abarth-primary-bg: #FEDB00;
$abarth-full-black: #000000;

$section-horizontal-padding: clamp(40px, 4vw, 90px);
$section-horizontal-padding-desktop: clamp(70px, 4vw, 90px);

$min-mobile-height: 568px;

$container-max-width: 1280px;

$font-weight-thin: 100;
$font-weight-ultralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-book: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$font-weight-extrabold: 900;

$header-size: 44px;
$header-size-desktop: 65px;

$transition-time: 400ms;
$transition-time-quick: 200ms;
$transition-time-quickest: 50ms;
$transition-easing: ease-in-out;

$nav-width: 224px;
$nav-width-desktop: 0px;

$screen-sm-min: 576.1px;
$screen-md-min: 768.1px;
$screen-lg-min: 992.1px;
$screen-desktop-min: 1024.1px;
$screen-xl-min: 1200.1px;
$screen-xxl-min: 1920.1px;
$screen-max-size: 2560px;
