@import "@styles/theme", "@styles/mixins", "@styles/animations";

.colorsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;

  p {
    font-size: 16px;
    font-weight: bold;
    align-self: center;
    color: #fff;
    width: auto;
  }
}

.colors {
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 12px 0;
  align-items: center;
  border-radius: 50px;

  nav {
    display: flex;
    margin: 0 0.6rem;

    button {
      margin: 0 10px;
      position: relative;
      width: 16px;
      height: 16px;
      border: none;

      &.active {
        &::after {
          position: absolute;
          top: -7px;
          left: -7px;
          display: block;
          width: 26px;
          height: 33px;
          content: url("../../../public/images/ui/iconActive.svg");
          z-index: 1;
        }

        .currCircleColor {
          border-radius: 8px;
          outline: 1px solid #000;
        }

        .currTextColor {
          display: block;
          position: absolute;
          top: 36px;
          left: 50%;
          color: #fff;
          text-align: center;
          transform: translateX(-50%);
          font-family: $font;
          font-size: 8px;
        }
      }

      .currCircleColor {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 8px;
        outline: 1px solid #fff;
      }

      .currTextColor {
        white-space: nowrap;
        display: none;
      }
    }
  }
}