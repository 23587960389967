@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  padding: 0 25px;
}

.info {
  .optional {
    display: flex;
    flex-direction: column;
    min-height: 230px;
    @include desktop {
      min-height: auto;
    }

    h3 {
      font-weight: 600;
      font-size: 1.3rem;
      text-transform: uppercase;
      position: relative;
      margin: 0 0 21px;

      &:before {
        position: absolute;
        bottom: -10px;
        left: 0;
        content: " ";
        display: block;
        height: 3px;
        width: 100%;
        background-color: #991241;
      }
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 18px 0 33px;
      padding: 0;
      column-gap: 10px;

      li {
        border-top: 2px solid #fff;
        min-width: 100px;
        padding-top: 4px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: clamp(0.7rem, 1vw, 0.6rem);
        color: #fff;
      }
    }
  }

  .price {
    p {
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      font-weight: $font-weight-book;

      strong {
        font-size: 1.5rem;
        color: #fff;
        font-weight: $font-weight-bold;
      }
    }

    .ctas {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-top: 1rem;
      align-items: flex-end;
    }

    .ctaLinks {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      width: 100%;
    }
  }

  .ctaButton {
    width: 100%;

    > div {
      color: white;
      height: 40px;
    }

    p {
      color: #fff;
      font-style: italic;
      text-transform: none;
    }
  }

  @include desktop {
    display: flex;
    flex-direction: row;
    padding: 0 48px;
    justify-content: space-between;

    .price {
      min-width: 250px;
    }
  }
}

.title {
  width: 100%;
  color: #fff;

  p {
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
  }
  h3 {
    width: 100%;
    text-align: center;
    display: inline-block;

    font-weight: $font-weight-extrabold;
    font-size: 2rem;
    text-align: start;
    text-transform: uppercase;
  }
}

.features {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  margin-bottom: 2rem;
  justify-content: center;
  min-height: 100px;
  position: relative;
}

.featureBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #000;
  width: 104px;
  min-height: 130px;

}

.featureImage {
  object-fit: cover;
}

.featureDescription {
  background-color: #000;
  height: 48px;
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: $font-weight-bold;
    text-align: center;
  }
}
