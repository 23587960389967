@import '@styles/theme', '@styles/mixins';

.container {
  position: fixed;
  height: $header-size;
  width: 100%;
  background-color: $background-header;
  z-index: 1001;
  top: 0;
  transition: top $transition-time-quick $transition-easing;

  &.hide {
    top: calc(#{$header-size * -1});
  }

  @include desktop {
    width: 100%;
    right: 0;
    top: 0 !important;
  }

  button {
    position: absolute;
    left: 0;
    top: 0;
    padding: 12px 14px;
  }

  .logoProduct {
    margin-left: 15px;
  }

  .mobile,
  .desktop {

    &,
    &>div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    width: 100%;
    height: 100%;
  }

  .desktop {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 27px;
    display: none;
  }

  @include desktop {
    background-color: transparent;
    position: inherit;
    height: $header-size-desktop;
    position: absolute;
    z-index: 1;

    .mobile {
      display: none;
    }

    .desktop {
      display: flex;
    }
  }
}

.logoHeaderDesktop {
  margin-right: 10px;
}

.title {
  color: $text-primary;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: -5px;
  font-weight: $font-weight-semibold;
  text-decoration: none;
}