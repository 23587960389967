@import "@styles/theme";

.contentHolder {
  background-color: $input-box-primary;
  color: $text-primary;
  transition: background-color $transition-time-quickest $transition-easing;
  padding: 5px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
}

.button {
  position: relative;
  display: block;
  text-decoration: none;
  &.larger {
    .contentHolder {
      padding: 12px 24px;
    }
    .content {
      font-size: 1.2rem;
    }
  }
  border: 1px solid transparent;
  .content {
    text-align: left !important;
    color: $text-primary;
    font-size: 0.9rem;
    &.withIcon:after {
      content: "\f019";
      font-family: $fontawesome;
      font-size: 0.9rem;
      margin-right: 3px;
    }
  }
  svg {
    color: $text-primary;
    flex-shrink: 0;
  }
  &:hover,
  &:active {
    .content {
      color: $text-primary;
    }
    border-color: $primary;
    svg {
      color: $primary;
    }
    .contentHolder {
      background-color: $input-box-secondary;
    }
  }

  &:active {
    .bg {
      left: 0px !important;
      top: 0px !important;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      // border-color: $primary !important;
    }
  }

  &.larger .bg {
    left: 7px;
    top: 7px;
  }

  &.secondary {
    .bg {
      border-color: $text-secondary;
    }
    .contentHolder {
      background-color: $input-box-primary;
      color: $text-primary;
    }
    &:hover,
    &:active {
      .content {
        color: $text-secondary;
      }
      border-color: $text-secondary;
      svg {
        color: $text-secondary;
      }
      .contentHolder {
        background-color: yellow;
      }
    }
  }
}
